// import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GetUser } from "../../Functions/diff";
// import { siteContext } from "../../Functions/siteContext";
import { LogoutApi } from "./functions/AuthApi";

const Account = () => {
  // const { siteState, setSiteState } = useContext(siteContext);
  const main_user = GetUser()

  return (
    <div className="h-full bg-white flex flex-col space-y-10 justify-center items-center">
      <h1 className="text-3xl font-bold">Кабинет пользователя</h1>
      <h2>Здравствуйте, {main_user.first_name} {main_user.sur_name}</h2>
      <button
        onClick={LogoutApi}
        className="mt-16 py-3 px-16 rounded-xl border-2 text-red-500 border-red-500 hover:bg-red-500 hover:text-white"
      >
        Выйти
      </button>
      <Link to="/account/change-pass" className="mt-16 py-3 px-16 rounded-xl border-2 text-red-500 border-red-500 hover:bg-red-500 hover:text-white">
        Поменять пароль
      </Link>
    </div>
  );
};

export default Account;
