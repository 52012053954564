import { checkAllFormLogin, checkPassChange } from "./CheckFormApi";
import axios from "axios";
import * as swal from "../../../Functions/SwallFire";
import Swal from "sweetalert2";

export function LoginApi(data: any) {
	const msg = checkAllFormLogin(data);
	if (msg.status === "fail") swal.fire_error(msg.msg);
	else if (msg.status === "success") {
		axios.post(`http://dnevnik.covcheg.org/account/login`, data).then((res) => {
			if (!res.data.success) swal.fire_error(res.data.msg);
			if (res.data.success) {
				localStorage.setItem("user", JSON.stringify(res.data.user));
				localStorage.setItem("token", res.data.token);
				swal.fire_success_href(res.data.msg, "/");
			}
		});
	}
}

export function LogoutApi() {
	Swal.fire({
		title: "Вы уверены?",
		text: "Вам прийдется снова зайти в аккаунт чтобы продолжить пользоваться сайтом!",
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		cancelButtonText: "Отмена",
		confirmButtonText: "Да, выйти!",
	}).then((result) => {
		if (result.isConfirmed) {
			swal.fire_success_href("Вы успешно вышли из аккаунта", "/");
			localStorage.removeItem("user");
			localStorage.removeItem("token");
			window.location.reload();
		}
	});
}

export function ChangePassApi(data: any) {
	const msg = checkPassChange(data);
	if (msg.status === "fail") swal.fire_error(msg.msg);
	else if (msg.status === "success") {
		axios
			.post(`http://dnevnik.covcheg.org/account/change-pass`, data)
			.then((res) => {
				if (!res.data.success) swal.fire_error(res.data.msg);
				if (res.data.success) swal.fire_success_href(res.data.msg, "/");
				window.location.reload();
			});
	}
}
