import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoginApi } from "./functions/AuthApi";

const LoginPage = () => {
  interface Ilogin {
    email: string;
    password: string;
  }
  const { register, handleSubmit } = useForm<Ilogin>();
  const onSubmit: SubmitHandler<Ilogin> = (data) => LoginApi(data);

  return (
    <div className="h-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mx-auto w-[36rem] flex flex-col items-center space-y-6 h-full justify-center text-3xl"
      >
        <h1>Форма авторизации</h1>
        <input
          type="email"
          {...register("email")}
          className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full"
          placeholder="Почта"
        />
        <input
          type="password"
          {...register("password")}
          className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full"
          placeholder="Пароль"
        />
        <button
          className="py-2 w-full rounded-xl border-2 border-blue-300 text-blue-300 hover:bg-blue-300 hover:text-white"
          type="submit"
        >
          Войти
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
