import Swal from 'sweetalert2'

export function fire_error(msg) {
    Swal.fire({
        title: 'Ой',
        text: msg,
        icon: 'error'
    })
}

export function fire_success(msg) {
    Swal.fire({
        title: 'Отлично',
        text: msg,
        icon: 'success'
    })
}

export function fire_success_href(msg, href) {
    Swal.fire({
        title: 'Отлично',
        text: msg,
        icon: 'success',
        // willClose: () => {
            //     window.location.href = href;
            // }
        })
}

export function fire_error_href(msg, href) {
    Swal.fire({
        title: 'Ой',
        text: msg,
        icon: 'error',
        // willClose: () => {
        //     window.location.href = href;
        // }
    })
}