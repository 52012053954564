import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CreateHomework from './CreateHomework';
import TeacherCabinet from './TeacherCabinet';

const TeacherPage = () => {
    return (
        <Routes>
            <Route path="" element={<TeacherCabinet />} />
            <Route path="create-homework/:class_n/:lesson_id" element={<CreateHomework />} />
        </Routes>
    );
};

export default TeacherPage;