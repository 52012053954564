import React from "react";
import { Route, Routes } from "react-router-dom";
import MainHome from "./MainHome";
import Homework from "./Homework";

const HomePage = () => {
  return (
    <Routes>
      <Route path="" element={<MainHome />} />
      <Route path="/homework" element={<Homework />} />
    </Routes>
  );
};

export default HomePage;
