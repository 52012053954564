import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { lesson_types } from "../../../Functions/consts/constants";
import { GetUser } from "../../../Functions/diff";
import * as swal from "../../../Functions/SwallFire";
import UserCardInTeacherPanel from "../components/UserCardInTeacherPanel";
import cn from "classnames";

const TeacherCabinet = () => {
	const main_user = GetUser();
	const [selectedClass, setSelectedClass] = useState(0);
	const [classes, setClasses] = useState([]);
	const [schedule, setSchedule] = useState<any>([]);
	const [IsReadyToShow, setReadyToShow] = useState(false);
	const [week_off, setWeek_off] = useState(0);
	const [lessons, setLessons] = useState<any[]>([]);

	const [homeworks, setHomeworks] = useState<string[]>([]);

	const days_dict: { [key: string]: string } = {
		Mon: "Понедельник",
		Tue: "Вторник",
		Wed: "Среда",
		Thu: "Четверг",
		Fri: "Пятница",
	};

	useEffect(() => {
		const url = `http://dnevnik.covcheg.org/admin/get-teacher-students?id=${main_user.id}`;
		const to_send = [0, "", 0];

		axios.post(url, to_send).then(({ data }) => {
			if (data.data_type === 1) setClasses(data.data);
		});
	}, []);

	useEffect(() => {
		const getSchedule = async () => {
			setReadyToShow(false);
			let class_n = selectedClass;
			if (class_n !== 0) {
				const url = `http://dnevnik.covcheg.org/schedule/get-schedule?class_n=${class_n}&week_off=${week_off}`;
				let answer = (await axios.post(url)).data;
				if (answer["success"] === true) setSchedule(answer.msg);
				else {
					if (answer.side === "right") setWeek_off(week_off - 1);
					if (answer.side === "left") setWeek_off(week_off + 1);
					swal.fire_error(answer.msg);
				}
				setReadyToShow(true);
			}
		};

		const url_get_hw = `http://dnevnik.covcheg.org/schedule/get-teacher-hw?teacher_id=${main_user.id}&class_n=${selectedClass}&week_off=${week_off}`;

		axios.post(url_get_hw).then(({ data }) => {
			if (data.success) {
				data.msg.forEach((lesson: any) => {
					setHomeworks((prev: any) => [...prev, lesson["lesson"]]);
				});
			}
		});

		getSchedule();
	}, [selectedClass, week_off]);

	useEffect(() => {
		const url = `http://dnevnik.covcheg.org/admin/get-teacher-students?id=${main_user.id}`;

		const to_send = [selectedClass, "", 0];

		axios.post(url, to_send).then(({ data }) => {
			if (data.data_type === 2) setLessons(data.data);
		});
	}, [selectedClass]);

	const is_in_lessons = (name: any) => {
		return lessons.indexOf(String(name)) !== -1;
	};

	const is_in_homeworks = (lesson_id: any) => {
		return homeworks.indexOf(lesson_id) !== -1;
	};

	return (
		<div className="h-full bg-white flex flex-col">
			<div className="mx-auto w-72 mt-20 sm:mt-5">
				<select
					name="class_n"
					placeholder="Выберите класс"
					defaultValue="def"
					onChange={(e) => setSelectedClass(Number(e.target.value))}
					className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
				>
					<option disabled value="def">
						Выберите класс
					</option>
					{classes.map((class_n, idx1) => {
						return (
							<option value={class_n} key={`selected class ${idx1}`}>
								{class_n} Класс
							</option>
						);
					})}
				</select>
			</div>

			{selectedClass !== 0 && (
				<div className="w-96 mx-auto  rounded-xl py-3">
					<div className="bg-slate-200 rounded-xl py-3 px-2 text-center font-bold">
						<h1 className="">Сдвиг в неделях относительно текущей</h1>
						<div className="flex w-full rounded-lg items-center text-center">
							<i
								className="bi bi-caret-left-fill text-3xl mb-1"
								onClick={() => setWeek_off(week_off - 1)}
							/>
							<input
								type="number"
								className="w-full rounded-2xl py-1.5 text-center"
								value={week_off}
								onChange={(e) => setWeek_off(Number(e.target.value))}
							/>
							<i
								className="bi bi-caret-right-fill text-3xl mb-1"
								onClick={() => setWeek_off(week_off + 1)}
							/>
						</div>
					</div>
				</div>
			)}

			{schedule !== undefined && IsReadyToShow && (
				<div>
					<div className="flex flex-wrap px-12">
						{schedule.map((day: any, idx1: any) => {
							let lessons = day["lessons"];
							let week_d: string = day["week_d_name"];
							return (
								<div
									className="bg-red-400 w-96 rounded-3xl text-center mx-auto pt-2 pb-3 my-2"
									key={`DivDaySchedule ${idx1}`}
								>
									<h1 className="text-xl font-bold">
										{days_dict[week_d]} {day["date"]}
									</h1>
									{lessons.map((lesson: any, idx2: any) => {
										return (
											<div
												className={`w-3/4 mx-auto py-2 px-2 ${
													lesson.type === ""
														? "bg-slate-100"
														: lesson_types[lesson.type]
												} rounded-2xl my-2`}
												key={`DivLessonSchedule ${idx2}`}
											>
												<p className="font-bold">{idx2 + 1}</p>
												<p
													id="lesson_name"
													className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full mb-1"
												>
													{lesson.name}
												</p>
												<Link
													to={
														is_in_lessons(lesson.name)
															? `create-homework/${selectedClass}/${lesson._id}`
															: ""
													}
													className=""
												>
													<p
														className={cn(
															"bg-blue-300 w-full rounded-xl py-1",
															{
																"cursor-not-allowed": !is_in_lessons(
																	lesson.name
																),
																"bg-red-500": !is_in_lessons(lesson.name),
															}
														)}
													>
														{is_in_homeworks(lesson._id)
															? "Изменить домашнюю работу"
															: "Создать домашнюю работу"}
													</p>
												</Link>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			)}
			{selectedClass === 0 && (
				<div className="mx-auto mt-8">
					<h1 className="font-bold text-5xl">Выберите класс</h1>
				</div>
			)}
		</div>
	);
};

export default TeacherCabinet;

{
	/* <div className="mx-auto flex w-2/4 mt-20 sm:mt-5">
        <select
          name="class_n"
          placeholder="Выберите класс"
          defaultValue="def"
          onChange={(e) => setSelectedClass(Number(e.target.value))}
          className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
        >
          <option disabled value="def">
            Выберите класс
          </option>
          {classes.map((class_n, idx1) => {
            return (
              <option value={class_n} key={`selected class ${idx1}`}>
                {class_n} Класс
              </option>
            );
          })}
        </select>
        {selectedClass !== 0 && (
          <div className="flex w-full">
            <select
              name="class_n"
              placeholder="Выберите урок"
              value={selectedLesson}
              onChange={(e) => setSelectedLesson(e.target.value)}
              className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
            >
              <option disabled value="">
                Выберите урок
              </option>
              {lessons.map((lesson, idx1) => {
                return (
                  <option value={lesson} key={`selected lesson ${idx1}`}>
                    {lesson}
                  </option>
                );
              })}
            </select>
            {selectedLesson !== "" && (
              <select
                name="strat_n"
                placeholder="Выберите страт"
                value={selectedStrat}
                onChange={(e) => setSelectedStrat(Number(e.target.value))}
                className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
              >
                <option disabled value="0">
                  Выберите страт
                </option>
                {strats.map((strat, idx1) => {
                  return (
                    <option value={strat} key={`selected lesson ${idx1}`}>
                      {strat}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <div className="flex flex-col w-2/3">
          <Link
            to="create-homework"
            className="mx-auto mt-3 pt-3 pb-3 px-8 mb-1 rounded-xl border-2 text-red-500 border-red-500 hover:bg-red-500 hover:border-red-300 hover:mb-0 hover:border-4 hover:text-white"
          >
            Создать домашнее задание
          </Link>
          {users.map((user: any, idx: any) => {
            // if (user.user_id.rank !== 1) {
            return (
              <UserCardInTeacherPanel
                user={user}
                key={`teacher card key${idx}`}
              />
            );
            // }
          })}
        </div>
      </div> */
}
