import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GetUser } from "../../Functions/diff";
import * as swal from "../../Functions/SwallFire";
import { lesson_types } from "../../Functions/consts/constants";
// import cn from "classnames";
import UseOutside from "../../Functions/useOutside";
import { siteContext } from "../../Functions/siteContext";

const Homework = () => {
	const main_user = GetUser();
	const days_dict: { [key: string]: string } = {
		Mon: "Понедельник",
		Tue: "Вторник",
		Wed: "Среда",
		Thu: "Четверг",
		Fri: "Пятница",
	};

	const [schedule, setSchedule] = useState<any>([]);
	const [IsReadyToShow, setReadyToShow] = useState(false);
	const [week_off, setWeek_off] = useState(0);
	const [homeworkLessons, setHomeworkLessons] = useState<string[]>([]);
	const [allHomework, setAllHomework] = useState<any>([]);
	const [dispHomework, setDispHomework] = useState<any>(false);

	const [selectedClass, setSelectedClass] = useState("1");

	const { ref, isOpen, setIsOpen } = UseOutside(false);
	const { siteState, setSiteState } = useContext(siteContext);
	const [lessonOpen, setLessonOpen] = useState<any>(false);

	useEffect(() => {
		const getSchedule = async () => {
			setReadyToShow(false);
			const url = `http://dnevnik.covcheg.org/schedule/get-schedule?class_n=${selectedClass}&week_off=${week_off}`;
			let answer = (await axios.post(url)).data;
			if (answer["success"] === true) setSchedule(answer.msg);
			else {
				if (answer.side === "right") setWeek_off(week_off - 1);
				if (answer.side === "left") setWeek_off(week_off + 1);
				swal.fire_error(answer.msg);
			}
			setReadyToShow(true);
		};

		if (main_user.rank === 0 && siteState.site.isLogged) {
			const url_get_hw = `http://dnevnik.covcheg.org/schedule/get-user-week-hw?user_id=${main_user.id}&week_off=${week_off}`;

			axios.post(url_get_hw).then(({ data }) => {
				if (data.success) {
					data.msg.forEach((hw: any) => {
						setHomeworkLessons((prev: any) => [...prev, hw.lesson]);
					});
					setAllHomework(data.msg);
				}
			});
		}

		getSchedule();
	}, [week_off, selectedClass]);

	useEffect(() => {
		if (main_user.rank === 0 && siteState.site.isLogged)
			setSelectedClass(main_user.class_n);
	}, [IsReadyToShow]);

	//   useEffect(() => {
	//     setDispHomework(
	//       allHomework.filter((t: any) => t.lesson === lessonOpen._id)[0]
	//     );
	//   }, [lessonOpen]);
	//   useEffect(() => {
	//     console.log(allHomework.filter((t: any) => t.lesson === lessonOpen._id)[0]);
	//   });

	const is_in_homeworkLessons = (lesson_id: any) => {
		return homeworkLessons.indexOf(lesson_id) !== -1;
	};

	return (
		<div className="h-full bg-white dark:bg-black flex flex-col pt-16 sm:pt-0">
			<div className="sm:w-96 mx-auto  rounded-xl py-3">
				<div className="bg-slate-200 rounded-xl py-3 px-2 text-center font-bold">
					<h1 className="">Сдвиг в неделях относительно текущей</h1>
					<div className="flex w-full rounded-lg items-center text-center">
						<i
							className="bi bi-caret-left-fill text-3xl mb-1"
							onClick={() => setWeek_off(week_off - 1)}
						/>
						<input
							type="number"
							className="w-full rounded-2xl py-1.5 text-center"
							value={week_off}
							onChange={(e) => setWeek_off(Number(e.target.value))}
						/>
						<i
							className="bi bi-caret-right-fill text-3xl mb-1"
							onClick={() => setWeek_off(week_off + 1)}
						/>
					</div>
				</div>
			</div>

			{schedule !== undefined && IsReadyToShow ? (
				<div>
					<div className="flex flex-wrap sm:px-12">
						{schedule.map((day: any, idx1: any) => {
							let lessons = day["lessons"];
							let week_d: string = day["week_d_name"];
							return (
								<div
									className="bg-red-400 w-96 rounded-3xl text-center mx-auto pt-2 pb-3 my-2 px-2"
									key={`DivDaySchedule ${idx1}`}
								>
									<h1 className="text-xl font-bold">
										{days_dict[week_d]} {day["date"]}
									</h1>
									{lessons.map((lesson: any, idx2: any) => {
										return (
											<div
												className={`${
													lesson.type === ""
														? "bg-slate-100"
														: lesson_types[lesson.type]
												} rounded-2xl my-2`}
												key={`DivLessonSchedule ${idx2}`}
											>
												{is_in_homeworkLessons(lesson._id) && (
													<div
														className={`sm:w-3/4 w-5/6 mx-auto py-2 px-2 ${
															lesson.type === ""
																? "bg-slate-100"
																: lesson_types[lesson.type]
														} rounded-2xl my-2`}
														key={`DivLessonSchedule ${idx2}`}
													>
														<p className="font-bold text-xl mb-2 mx-auto">
															{idx2 + 1}
														</p>
														<p
															id="lesson_name"
															className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full mb-1"
														>
															{lesson.name}
														</p>

														<p
															id="lesson_notes"
															className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
														>
															{lesson.notes === ""
																? "Нет пометок"
																: lesson.notes}
														</p>
														{/* allHomework.filter(
                                (t: any) => t.lesson === lesson._id
                              )[0].title */}

														<div className="bg-green-300 rounded-xl mt-1">
															<p className="text-xl">Задание:</p>
															<p className="text-md">
																{
																	allHomework.filter(
																		(t: any) => t.lesson === lesson._id
																	)[0].title
																}
															</p>
															{allHomework.filter(
																(t: any) => t.lesson === lesson._id
															)[0].description !== "" && (
																<div>
																	<p className="text-xl">Описание:</p>
																	<p className="text-md">
																		{
																			allHomework.filter(
																				(t: any) => t.lesson === lesson._id
																			)[0].description
																		}
																	</p>
																</div>
															)}

															{allHomework.filter(
																(t: any) => t.lesson === lesson._id
															)[0].links !== "" && (
																<div>
																	<p>Ссылки:</p>
																	{allHomework
																		.filter(
																			(t: any) => t.lesson === lesson._id
																		)[0]
																		.links.split(" ")
																		.map((link: any, idx: any) => {
																			return (
																				<a
																					href={link}
																					className="lg:text-base sm:text-sm text-xs text-blue-900"
																				>
																					<p>{link}</p>
																				</a>
																			);
																		})}
																</div>
															)}
														</div>
													</div>
												)}
											</div>
											//   <div
											//     className={`sm:w-3/4 w-5/6 mx-auto py-2 px-2 ${
											//       lesson.type === ""
											//         ? "bg-slate-100"
											//         : lesson_types[lesson.type]
											//     } rounded-2xl my-2`}
											//     key={`DivLessonSchedule ${idx2}`}
											//     // onClick={() => {
											//     //   if (is_in_homeworkLessons(lesson._id)) {
											//     //     setLessonOpen(lesson);
											//     //     setIsOpen(true);
											//     //   }
											//     // }}
											//   >
											//     <span className="flex">
											//       {is_in_homeworkLessons(lesson._id) && (
											//         <span className="inline-flex rounded-full h-3 w-3 bg-rose-900" />
											//       )}

											//       <p className="font-bold mx-auto">{idx2 + 1}</p>
											//     </span>

											//     <p
											//       id="lesson_name"
											//       className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full mb-1"
											//     >
											//       {lesson.name}
											//     </p>

											//     <p
											//       id="lesson_notes"
											//       className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
											//     >
											//       {lesson.notes === "" ? "Нет пометок" : lesson.notes}
											//     </p>
											//   </div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div>
					<h1>ЗАГРУЗКА</h1>
				</div>
			)}
		</div>
	);
};

export default Homework;
