import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ChangePassApi } from "./functions/AuthApi";

const ChangePass = () => {
  interface IPassChange {
    password_before: string;
    password_new: string;
    password_new_repeat: string;
  }
  const { register, handleSubmit } = useForm<IPassChange>();
  const onSubmit: SubmitHandler<IPassChange> = (data) => ChangePassApi(data);

  return (
    <div className="h-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mx-auto w-[36rem] flex flex-col items-center space-y-6 h-full justify-center text-3xl"
      >
        <h1>Форма смены пароля</h1>
        <input
          type="password"
          {...register("password_before")}
          className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full"
          placeholder="Старый пароль"
        />
        <input
          type="password"
          {...register("password_new")}
          className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full"
          placeholder="Новый пароль"
        />
        <input
          type="password"
          {...register("password_new_repeat")}
          className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full"
          placeholder="Еще раз новый пароль"
        />
        <button
          className="py-2 w-full rounded-xl border-2 border-blue-300 text-blue-300 hover:bg-blue-300 hover:text-white"
          type="submit"
        >
          Изменить
        </button>
      </form>
    </div>
  );
};

export default ChangePass;
