import axios from "axios";
import * as swal from "../../../Functions/SwallFire";
import { FullChechFormCreate } from "./CheckFormApi";

export function CreateUserApi(data: any) {
	const msg = FullChechFormCreate(data);
	if (msg.status === "fail") swal.fire_error(msg.msg);
	else if (msg.status === "success") {
		axios
			.post(`http://dnevnik.covcheg.org/admin/create-user`, data)
			.then((res) => {
				if (!res.data.success) swal.fire_error(res.data.msg);
				if (res.data.success) swal.fire_success_href(res.data.msg, "/admin");
			});
	}
}
