import { GetRank } from "../components/Functions/diff";

const RequireRankToShow = ({ children, rank, type }: any) => {
  if (GetRank() >= Number(rank) && type === "ge") {
    return children;
  }
  if (GetRank() === Number(rank) && type === "e") {
    return children;
  }

  return "";
};

export default RequireRankToShow;
