import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { GetRank } from "../../Functions/diff";
import { CreateUserApi } from "./functions/CreateUserApi";
import cn from "classnames";

const CreateUser = () => {
  interface ICreateUser {
    first_name: string;
    sur_name: string;
    email: string;
    rank: number;
    class_n: number;
  }
  const [err, setErr] = useState("");
  const [rank, setRank] = useState(0);
  const { register, handleSubmit } = useForm<ICreateUser>();
  const onSubmit: SubmitHandler<ICreateUser> = (data) => CreateUserApi(data);

  const FastCheckRank = () => {
    const rank = GetRank();
    const data = {
      rank: Number(
        (document.getElementsByName("rank")[0] as HTMLInputElement).value
      ),
      class_n: Number(
        (document.getElementsByName("class_n")[0] as HTMLInputElement).value
      ),
    };
    setRank(data.rank);
    if (rank <= data.rank) {
      setErr("Вы не можете ставить ранг такой же как у вас или выше");
    } else {
      setErr("");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="mx-auto w-[36rem] flex flex-col items-center space-y-6 h-full justify-center text-3xl"
    >
      <h1>Форма регистрации</h1>
      <input
        {...register("first_name")}
        type="text"
        className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full"
        placeholder="Имя"
      />
      <input
        {...register("sur_name")}
        type="text"
        className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full"
        placeholder="Фамилия"
      />
      <input
        {...register("email")}
        type="email"
        className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full"
        placeholder="Почта"
      />
      <input
        {...register("rank")}
        onChange={FastCheckRank}
        name="rank"
        type="number"
        className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full"
        placeholder="Ранг"
      />
      <input
        {...register("class_n")}
        name="class_n"
        type="number"
        className={cn("border-2 bg-slate-200 rounded-xl px-5 py-3 w-full", {
          hidden: rank > 2 || rank === 1,
        })}
        placeholder="Класс"
      />
      <p
        className={cn(
          "border-2 border-red-600 px-12 py-4 text-red-700 rounded-xl",
          { hidden: err === "" }
        )}
      >
        {err}
      </p>
      <button
        className="py-2 w-full rounded-xl border-2 border-blue-300 text-blue-300 hover:bg-blue-300 hover:text-white"
        type="submit"
      >
        Регистрация
      </button>
    </form>
  );
};

export default CreateUser;
