import axios from "axios";
import React, { useEffect, useState } from "react";
import { GetUser } from "../../Functions/diff";
import UserCardInAdminPanel from "./components/UserCardInAdminPanel";
// import * as swal from "../../Functions/SwallFire";

const UserList = () => {
	const main_user = GetUser();
	const [users, setUsers] = useState<any[]>();
	const [selectedClass, setSelectedClass] = useState(main_user.class_n);

	useEffect(() => {
		const url = `http://dnevnik.covcheg.org/admin/get-users-selected?class_n=${selectedClass}`;
		axios.post(url).then((data) => {
			setUsers(data.data);
		});
	}, [selectedClass]);

	return (
		<div className="w-full h-full">
			<div className="mx-auto w-96">
				{main_user.rank === 2 ? (
					<p className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full mt-3">
						Ваш класс: {main_user.class_n}
					</p>
				) : (
					<select
						placeholder="Выберите класс"
						defaultValue={String(selectedClass)}
						onChange={(e) => setSelectedClass(Number(e.target.value))}
						className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full mt-3"
					>
						<option disabled>Выберите класс</option>
						<option value="0">Учителя</option>
						<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
						<option value="5">5</option>
						<option value="6">6</option>
						<option value="7">7</option>
						<option value="8">8</option>
						<option value="9">9</option>
						<option value="10">10</option>
						<option value="11">11</option>
					</select>
				)}
			</div>
			<div className="w-full md:w-2/3 lg:w-full xl:w-2/3 mx-auto">
				{users !== undefined ? (
					<div>
						{users.map((user, idx) => {
							return (
								<UserCardInAdminPanel
									key={`UserCardInAdminPanel ${idx}`}
									data={user}
								/>
							);
						})}
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default UserList;
