import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as swal from "../../Functions/SwallFire";
import RequireRankToShow from "../../../hoc/RequireRankToShow";
import cn from "classnames";
import { FullChechFormUpdateUser } from "./functions/CheckFormApi";

const ChangeUser = () => {
	let updated_strats: any = {};
	const [isReadyToShow, setIsReadyToShow] = useState(false);
	const [data, setData] = useState<any>();
	const [stratsToShow, setStratsToShow] = useState<any>([]);
	const [rank, setRank] = useState(0);
	const { id } = useParams();

	const [teacher, setTeacher] = useState<any>({});

	const getUser = async () => {
		setIsReadyToShow(false);
		const url = `http://dnevnik.covcheg.org/admin/get-user-to-change?id=${id}`;
		const data = await axios.post(url);
		if (!data.data.success) {
			swal.fire_error_href(data.data.msg, "../");
		} else {
			const _data = await getStarates(data.data.msg);
			if (_data.rank === 1) getTeacher();
			setData(_data);
			setRank(_data.rank);
			setIsReadyToShow(true);
		}
	};

	useEffect(() => {
		getUser();
	}, []);

	const updateUser = () => {
		let _data: any = {};
		_data["first_name"] = (
			document.getElementById("first_name") as HTMLInputElement
		).value;
		_data["sur_name"] = (
			document.getElementById("sur_name") as HTMLInputElement
		).value;
		_data["class_n"] = Number(
			(document.getElementById("class_n") as HTMLInputElement).value
		);
		_data["rank"] = rank;
		_data["id"] = data._id;

		const resp = FullChechFormUpdateUser(_data);
		if (resp.status !== "success") swal.fire_error(resp.msg);
		if (resp.status === "success") {
			updateStrats("");
			const url = `http://dnevnik.covcheg.org/admin/update-user`;
			axios.post(url, _data).then((data) => {
				if (data.data.success) {
					getUser();
					swal.fire_success_href(data.data.msg, "");
				} else swal.fire_error_href(data.data.msg, "");
			});
		}
	};

	const getTeacher = () => {
		const url = `http://dnevnik.covcheg.org/admin/get-teacher?id=${id}`;
		axios.post(url).then((data) => {
			if (!data.data.success) swal.fire_error_href(data.data.msg, "../");
			setTeacher(data.data.msg);
		});
	};

	const delete_strat = (strat_id: any) => {
		const url = `http://dnevnik.covcheg.org/admin/delete-user-strat?id=${strat_id}`;
		axios.post(url).then((data) => {
			if (!data.data.success) {
				swal.fire_error_href(data.data.msg, "");
			} else {
				getUser();
				swal.fire_success_href(data.data.msg, "");
			}
		});
	};

	const getStarates = async (data: any) => {
		const url = `http://dnevnik.covcheg.org/admin/get-user-strats?id=${id}`;
		let _data = data;
		const strats = await axios.post(url);
		if (!strats.data.success) {
			swal.fire_error_href(strats.data.msg, "../");
		} else {
			_data["strats"] = strats.data.msg;
			setStratsToShow(strats.data.msg);
			return _data;
		}
	};

	const updateStrats = (strat_to_add: any) => {
		let strats_to_send: any = [];

		if (strat_to_add !== "") strats_to_send.push(strat_to_add);

		Object.values(updated_strats).forEach((strat_el) =>
			strats_to_send.push(strat_el)
		);

		const url = `http://dnevnik.covcheg.org/admin/add-user-strats?id=${id}`;
		axios.post(url, strats_to_send).then((data) => {
			if (data.data.success) {
				getUser();
				swal.fire_success_href(data.data.msg, "");
			}
		});
	};

	const updateStrat = (
		strat_id: any,
		lesson: any,
		strat_n: any,
		class_n: any
	) => {
		if (!updated_strats[strat_id]) {
			updated_strats[strat_id] = {};
		}
		if (data.rank !== 1) class_n = data.class_n;
		updated_strats[strat_id]["id"] = strat_id;
		updated_strats[strat_id]["lesson"] = lesson;
		updated_strats[strat_id]["class_n"] = class_n;
		updated_strats[strat_id]["strat_n"] = strat_n;
	};

	const addStrat = (lesson: any, strat_n: any, class_n: any) => {
		if (data.rank !== 1) class_n = data.class_n;
		if (lesson === "") swal.fire_error("Введите урок");
		else if (strat_n === 0) swal.fire_error("Введите страт");
		else if (class_n === 0) swal.fire_error("Введите класс");
		else {
			let strat: any = {};
			strat["lesson"] = lesson;
			strat["strat_n"] = strat_n;
			strat["class_n"] = class_n;

			updateStrats(strat);

			(document.getElementById("strat_n_to_add") as HTMLInputElement).value =
				"";
			if (data.rank === 1)
				(
					document.getElementById("strat_class_to_add") as HTMLInputElement
				).value = "";
			(
				document.getElementById("strat_lesson_to_add") as HTMLInputElement
			).value = "";
		}
	};

	return (
		<div className="h-full w-full bg-white flex flex-col items-center">
			{/* <p>CAT</p>
      <button className="py-2 px-4 bg-red-400 rounded-xl" onClick={test}>TEST</button> */}
			<div className="flex mx-auto h-full items-center w-full">
				<div className="flex-col items-center text-center mx-auto text-2xl w-full 2xl:w-3/6 xl:w-4/6 lg:w-5/6 md:w-4/5">
					<h1 className="text-3xl font-bold">Изменение пользователя</h1>
					{isReadyToShow ? (
						<div className="mx-auto w-full">
							<div className="sm:flex items-center w-full justify-between mt-8">
								<p className="mr-2">Имя:</p>
								<input
									id="first_name"
									type="text"
									className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full sm:w-4/5 text-center"
									placeholder="Имя"
									defaultValue={data.first_name}
								/>
							</div>

							<div className="sm:flex items-center w-full justify-between mt-3">
								<p className="mr-2">Фамилия:</p>
								<input
									id="sur_name"
									type="text"
									className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full sm:w-4/5 text-center"
									placeholder="Фамилия"
									defaultValue={data.sur_name}
								/>
							</div>

							<RequireRankToShow rank="3" type="ge">
								<div className="sm:flex items-center w-full justify-between mt-3">
									<p className="mr-2">Класс:</p>
									<input
										id="class_n"
										type="number"
										className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full sm:w-4/5 text-center"
										placeholder="Имя"
										defaultValue={data.class_n}
									/>
								</div>
							</RequireRankToShow>

							<RequireRankToShow rank="4" type="ge">
								<div className="sm:flex items-center w-full justify-between mt-3">
									<p className="mr-2">Ранг:</p>
									<input
										name="rank"
										type="number"
										className="border-2 bg-slate-200 rounded-xl px-5 py-3 w-full sm:w-4/5 text-center"
										placeholder="Ранг"
										defaultValue={data.rank}
										onChange={(e) => setRank(Number(e.target.value))}
									/>
								</div>
							</RequireRankToShow>

							<div className="sm:flex items-center w-full justify-between mt-3">
								<div className="flex-col w-full bg-slate-200 rounded-xl">
									<p className="pt-1">Страты:</p>
									{stratsToShow.map((strat: any, idx: any) => {
										return (
											<div key={`strat idx: ${idx}`} className="">
												<div className="md:flex w-full justify-between px-0 md:px-2 py-3 items-center">
													<input
														id={`strat_lesson_${strat._id}`}
														type="text"
														className={cn(
															"border-2 bg-slate-300 rounded-xl px-5 py-3 text-center w-4/5 md:w-2/5",
															{ "cursor-not-allowed": strat.ro }
														)}
														placeholder="Урок"
														defaultValue={strat.lesson}
														onChange={() =>
															updateStrat(
																strat._id,
																(
																	document.getElementById(
																		`strat_lesson_${strat._id}`
																	) as HTMLInputElement
																).value,
																Number(
																	(
																		document.getElementById(
																			`strat_n_${strat._id}`
																		) as HTMLInputElement
																	).value
																),
																Number(
																	(
																		document.getElementById(
																			`strat_class_${strat._id}`
																		) as HTMLInputElement
																	)?.value
																)
															)
														}
													/>
													<hr className="sm:hidden" />
													<i
														className={cn("bi bi-dash-circle text-3xl", {
															hidden: strat.ro,
														})}
														onClick={() => delete_strat(strat._id)}
													/>
													<input
														id={`strat_n_${strat._id}`}
														type="number"
														className={cn(
															"border-2 bg-slate-300 rounded-xl px-5 py-3 text-center w-4/5 md:w-2/5",
															{ "cursor-not-allowed": strat.ro }
														)}
														placeholder="Страт"
														defaultValue={Number(strat.strat_n)}
														onChange={() =>
															updateStrat(
																strat._id,
																(
																	document.getElementById(
																		`strat_lesson_${strat._id}`
																	) as HTMLInputElement
																).value,
																Number(
																	(
																		document.getElementById(
																			`strat_n_${strat._id}`
																		) as HTMLInputElement
																	).value
																),
																Number(
																	(
																		document.getElementById(
																			`strat_class_${strat._id}`
																		) as HTMLInputElement
																	)?.value
																)
															)
														}
													/>
													<hr className="sm:hidden" />
													{data.rank === 1 ? (
														<input
															id={`strat_class_${strat._id}`}
															type="number"
															className={cn(
																"border-2 bg-slate-300 rounded-xl px-5 py-3 text-center w-4/5 md:w-2/5",
																{ "cursor-not-allowed": strat.ro }
															)}
															placeholder="Класс"
															defaultValue={Number(strat.class_n)}
															onChange={() =>
																updateStrat(
																	strat._id,
																	(
																		document.getElementById(
																			`strat_lesson_${strat._id}`
																		) as HTMLInputElement
																	).value,
																	Number(
																		(
																			document.getElementById(
																				`strat_n_${strat._id}`
																			) as HTMLInputElement
																		).value
																	),
																	Number(
																		(
																			document.getElementById(
																				`strat_class_${strat._id}`
																			) as HTMLInputElement
																		).value
																	)
																)
															}
														/>
													) : (
														""
													)}
												</div>
											</div>
										);
									})}
									<div className="md:flex w-full items-center justify-between px-0 md:px-2 py-3">
										<input
											id="strat_lesson_to_add"
											type="text"
											className="border-2 bg-slate-300 rounded-xl px-5 py-3 text-center w-4/5 md:w-2/5"
											placeholder="Урок"
										/>
										<hr className="sm:hidden" />
										<i
											className="bi bi-plus-circle text-4xl"
											onClick={() =>
												addStrat(
													(
														document.getElementById(
															"strat_lesson_to_add"
														) as HTMLInputElement
													).value,
													Number(
														(
															document.getElementById(
																"strat_n_to_add"
															) as HTMLInputElement
														).value
													),
													Number(
														(
															document.getElementById(
																"strat_class_to_add"
															) as HTMLInputElement
														)?.value
													)
												)
											}
										/>
										<input
											id="strat_n_to_add"
											type="number"
											className="border-2 bg-slate-300 rounded-xl px-5 py-3 text-center w-4/5 md:w-2/5"
											placeholder="Страт"
										/>
										<hr className="sm:hidden" />

										{data.rank === 1 ? (
											<div className="flex items-center w-4/5 md:w-2/5">
												<input
													id="strat_class_to_add"
													type="number"
													className="border-2 bg-slate-300 rounded-xl px-5 py-3 text-center w-full"
													placeholder="Класс"
												/>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
							<button
								onClick={updateUser}
								className="w-full border-[3px] border-red-300 hover:bg-red-600 hover:border-red-400 hover:text-white rounded-xl py-2 mt-3"
							>
								Сохранить
							</button>
							<button
								onClick={getUser}
								className="w-full border-[3px] border-red-300 hover:bg-red-600 hover:border-red-400 hover:text-white rounded-xl py-2 mt-3"
							>
								Test
							</button>
						</div>
					) : (
						"Загрузка"
					)}
				</div>
			</div>
		</div>
	);
};

export default ChangeUser;
