import React from "react";
import Account from "./Account";
import LoginPage from "./Login";
import { Route, Routes } from "react-router-dom";
import ChangePass from "./ChangePass";

const AccountPage = () => {
  return (
    <Routes>
      <Route path="" element={<Account />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="change-pass" element={<ChangePass />} />
      {/* <Route path="/admin" element={<MainAdmin />} /> */}
    </Routes>
  );
};

export default AccountPage;
