// react
// import { createContext, useContext } from "react";
import { createContext } from "react";

// создаем контекст
export const siteContext = createContext();

// начальное состояние
export const initialState = {
  site: {
    isLogged: false,
    theme: 'light'
  },
};

// const { siteState, setSiteState } = useContext(siteContext);

// // провайдер
// export const siteProvider = ({ children }) => {
//   const [state, updateState] = useState(initialState)

//   return (
//     <siteProvider.Provider value={{ state, updateState }}>
//       {children}
//     </siteProvider.Provider>
//   )
// }

// // утилита для извлечения значений из контекста
// export const useSiteContext = () => useContext(siteContext)
