import { Navigate } from "react-router-dom";
import { GetRank } from "../components/Functions/diff";

const RequireRankToOpen = ({ children, rank, type }: any) => {
  if (GetRank() >= Number(rank) && type === "ge") {
    return children;
  }
  if (GetRank() === Number(rank) && type === "e") {
    return children;
  }

  return <Navigate to="/" />;
};

export default RequireRankToOpen;
