import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as swal from "../../../Functions/SwallFire";
import { GetUser } from "../../../Functions/diff";
import UserCardInTeacherHomework from "../components/UserCardInTeacherHomework";

const CreateHomework = () => {
	const { class_n, lesson_id } = useParams();

	const main_user = GetUser();
	const [selectedClass, setSelectedClass] = useState(0);
	const [classes, setClasses] = useState([]);
	const [selectedLesson, setSelectedLesson] = useState("");
	const [lessons, setLessons] = useState([]);
	const [selectedStrat, setSelectedStrat] = useState(0);
	const [strats, setStrats] = useState([]);
	const [users, setUsers] = useState([]);
	const [homework, setHomework] = useState<{ [key: string]: string }>({});
	const [isHomeworkExists, setIsHomeworkExists] = useState(false);

	const [usersToHomeWork, setUsersToHomeWork] = useState<any>([]);

	useEffect(() => {
		const url_to_classes = `http://dnevnik.covcheg.org/admin/get-teacher-students?id=${main_user.id}`;
		const url_to_lesson_hw = `http://dnevnik.covcheg.org/schedule/get-lesson-hw?lesson_id=${lesson_id}`;
		const to_send = [0, "", 0];

		axios.post(url_to_classes, to_send).then(({ data }) => {
			if (data.data_type === 1) setClasses(data.data);
		});

		axios.post(url_to_lesson_hw).then(({ data }) => {
			if (data.success) {
				if (data.msg) {
					setHomework(data.msg.hw);
					data.msg.users.forEach((user: any) => {
						let user_to_add = {
							id: user["_id"],
							data: {
								first_name: user["first_name"],
								sur_name: user["sur_name"],
							},
						};
						setUsersToHomeWork([...usersToHomeWork, user_to_add]);
					});
					setIsHomeworkExists(true);
				}
			}
		});
	}, []);

	useEffect(() => {
		const url = `http://dnevnik.covcheg.org/admin/get-teacher-students?id=${main_user.id}`;
		const to_send = [selectedClass, "", 0];
		setSelectedLesson("");
		setUsers([]);

		axios.post(url, to_send).then(({ data }) => {
			if (data.data_type === 2) setLessons(data.data);
		});
	}, [selectedClass]);

	useEffect(() => {
		const url = `http://dnevnik.covcheg.org/admin/get-teacher-students?id=${main_user.id}`;
		const to_send = [selectedClass, selectedLesson, 0];
		setUsers([]);
		setSelectedStrat(0);

		axios.post(url, to_send).then(({ data }) => {
			if (data.data_type === 3) setStrats(data.data);
		});
	}, [selectedLesson]);

	useEffect(() => {
		const url = `http://dnevnik.covcheg.org/admin/get-teacher-students?id=${main_user.id}`;
		const to_send = [selectedClass, selectedLesson, selectedStrat];

		axios.post(url, to_send).then(({ data }) => {
			if (data.data_type === 4) setUsers(data.data);
		});
	}, [selectedStrat]);

	const addStratToHomeWork = () => {
		users.forEach((user) => {
			if (user["user_id"]["rank"] !== 1) {
				if (
					usersToHomeWork.filter(
						(t: any) => t.id === user["user_id"]["_id"]
					)[0] === undefined
				) {
					setUsersToHomeWork([
						{
							id: user["user_id"]["_id"],
							data: {
								first_name: user["user_id"]["first_name"],
								sur_name: user["user_id"]["sur_name"],
							},
						},
						...usersToHomeWork,
					]);
				}
			}
		});
	};

	const deleteFromHomeWork = (data: any) => {
		setUsersToHomeWork(
			[...usersToHomeWork].filter((t: any) => t.data !== data)
		);
	};

	const createHomework = () => {
		const url = `http://dnevnik.covcheg.org/schedule/set-lesson-homework?lesson_id=${lesson_id}`;

		let users: any = [];
		usersToHomeWork.forEach((user: any) => {
			users.push(user.id);
		});

		const data = {
			title: (document.getElementById("title") as HTMLInputElement).value,
			description: (document.getElementById("description") as HTMLInputElement)
				.value,
			links: (document.getElementById("links") as HTMLInputElement).value,
			users: users,
			teacher_id: main_user.id,
			class_n: class_n,
		};

		axios.post(url, data).then(({ data }) => {
			if (!data.success) swal.fire_error(data.msg);
			if (data.success) swal.fire_success_href(data.msg, "../..");
		});
	};

	const updateHomework = () => {
		const url = `http://dnevnik.covcheg.org/schedule/update-lesson-homework?homework_id=${homework._id}`;

		let users: any = [];
		usersToHomeWork.forEach((user: any) => {
			users.push(user.id);
		});

		const data = {
			title: (document.getElementById("title") as HTMLInputElement).value,
			description: (document.getElementById("description") as HTMLInputElement)
				.value,
			links: (document.getElementById("links") as HTMLInputElement).value,
			users: users,
		};

		axios.post(url, data).then(({ data }) => {
			if (!data.success) swal.fire_error(data.msg);
			if (data.success) swal.fire_success_href(data.msg, "../..");
		});
	};

	const deleteHomework = () => {
		const url = `http://dnevnik.covcheg.org/schedule/delete-lesson-homework?homework_id=${homework._id}`;

		axios.post(url).then(({ data }) => {
			if (!data.success) swal.fire_error(data.msg);
			if (data.success) swal.fire_success_href(data.msg, "../..");
		});
	};

	return (
		<div className="h-full bg-white flex flex-col">
			<div className="mx-auto flex w-2/4 mt-20 sm:mt-5">
				<select
					name="class_n"
					placeholder="Выберите класс"
					defaultValue="def"
					onChange={(e) => setSelectedClass(Number(e.target.value))}
					className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
				>
					<option disabled value="def">
						Выберите класс
					</option>
					{classes.map((class_n, idx1) => {
						return (
							<option value={class_n} key={`selected class ${idx1}`}>
								{class_n} Класс
							</option>
						);
					})}
				</select>
				{selectedClass !== 0 && (
					<div className="flex w-full">
						<select
							name="class_n"
							placeholder="Выберите урок"
							value={selectedLesson}
							onChange={(e) => setSelectedLesson(e.target.value)}
							className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
						>
							<option disabled value="">
								Выберите урок
							</option>
							{lessons.map((lesson, idx1) => {
								return (
									<option value={lesson} key={`selected lesson ${idx1}`}>
										{lesson}
									</option>
								);
							})}
						</select>
						{selectedLesson !== "" && (
							<select
								name="strat_n"
								placeholder="Выберите страт"
								value={selectedStrat}
								onChange={(e) => setSelectedStrat(Number(e.target.value))}
								className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
							>
								<option disabled value="0">
									Выберите страт
								</option>
								{strats.map((strat, idx1) => {
									return (
										<option value={strat} key={`selected lesson ${idx1}`}>
											{strat}
										</option>
									);
								})}
							</select>
						)}
						{selectedStrat !== 0 && (
							<i
								className="bi bi-plus-circle text-2xl items-center"
								onClick={addStratToHomeWork}
							/>
						)}
					</div>
				)}
			</div>
			<div className="flex justify-center">
				<div className="flex flex-col w-2/3">
					<div className="bg-slate-200 rounded-xl mt-5 py-4 text-center">
						<h1>Выбранные пользователи:</h1>
						<div className="flex flex-wrap justify-between">
							{usersToHomeWork.map((user: any, idx: any) => {
								return (
									<div
										className="flex mx-auto w-[22%] justify-between items-center rounded-xl h-16 bg-red-300"
										key={`selected homework key${idx}`}
									>
										<p className="mx-8 w-1/3 text-xl">
											{user.data.first_name} {user.data.sur_name}
										</p>
										<i
											className="bi bi-dash-circle text-2xl pr-4"
											onClick={() => deleteFromHomeWork(user.data)}
										/>
									</div>
								);
							})}
						</div>
					</div>
					{users.map((user: any, idx: any) => {
						if (user.user_id.rank !== 1) {
							return (
								<UserCardInTeacherHomework
									user={user}
									setUsersToHomeWork={setUsersToHomeWork}
									usersToHomeWork={usersToHomeWork}
									key={`teacher card key${idx}`}
								/>
							);
						}
					})}
					<div className="py-3 px-5 mt-2 bg-slate-200 rounded-xl">
						<h1 className="text-center text-2xl font-bold mb-3">
							Форма создания домашнего задания
						</h1>
						{isHomeworkExists && (
							<h1 className="text-center text-xl mt-3">Заголовок</h1>
						)}
						<input
							type="text"
							className="border-2 bg-slate-300 rounded-xl px-5 py-3 w-full text-xl"
							id="title"
							placeholder="Заголовок"
							defaultValue={isHomeworkExists ? homework["title"] : ""}
						/>
						{isHomeworkExists && (
							<h1 className="text-center text-xl mt-3">Описание</h1>
						)}
						<textarea
							className="border-2 bg-slate-300 rounded-xl px-5 py-3 w-full text-xl mt-2"
							id="description"
							placeholder="Описание"
							defaultValue={isHomeworkExists ? homework["description"] : ""}
						/>
						{isHomeworkExists && (
							<h1 className="text-center text-xl mt-3">Ссылки</h1>
						)}
						<textarea
							className="border-2 bg-slate-300 rounded-xl px-5 py-3 w-full text-xl"
							id="links"
							placeholder="Ссылки (к сожалению файлы не прикрепить, но их можно выложить на яндекс или гугл облако и выложить ссылки сюда), разделять пробелом"
							defaultValue={isHomeworkExists ? homework["links"] : ""}
						/>
						<div className="flex w-full">
							<button
								className="py-2 w-full rounded-xl border-2 border-blue-300 text-blue-300 hover:bg-blue-300 hover:text-white"
								onClick={isHomeworkExists ? updateHomework : createHomework}
							>
								{isHomeworkExists ? "Обновить" : "Создать"}
							</button>
							{isHomeworkExists && (
								<button
									className="py-2 w-full rounded-xl border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white"
									onClick={deleteHomework}
								>
									Удалить
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateHomework;
