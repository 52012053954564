import axios from "axios";
import React, { useEffect, useState } from "react";
import RequireRankToShow from "../../../hoc/RequireRankToShow";
import { lesson_types } from "../../Functions/consts/constants";
import * as swal from "../../Functions/SwallFire";

const Schedule = () => {
	const days_dict: { [key: string]: string } = {
		Mon: "Понедельник",
		Tue: "Вторник",
		Wed: "Среда",
		Thu: "Четверг",
		Fri: "Пятница",
	};

	let updated_lessons: any = {};
	let is_to_update_lessons = false;

	const [schedule, setSchedule] = useState<any>([]);
	const [IsReadyToShow, setReadyToShow] = useState(false);
	const [selectedClass, setSelectedClass] = useState("1");
	const [week_off, setWeek_off] = useState(0);

	useEffect(() => {
		const getSchedule = async () => {
			setReadyToShow(false);
			let class_n = Number(selectedClass);
			const url = `http://dnevnik.covcheg.org/schedule/get-schedule?class_n=${class_n}&week_off=${week_off}`;
			let answer = (await axios.post(url)).data;
			if (answer["success"] === true) setSchedule(answer.msg);
			else {
				if (answer.side === "right") setWeek_off(week_off - 1);
				if (answer.side === "left") setWeek_off(week_off + 1);
				swal.fire_error(answer.msg);
			}
			setReadyToShow(true);
		};
		getSchedule();
	}, [selectedClass, week_off]);

	const send_updates = () => {
		const updateSchedule = async () => {
			if (is_to_update_lessons) {
				const url = `http://dnevnik.covcheg.org/schedule/update-schedule`;
				let answer = (await axios.post(url, updated_lessons)).data;
				if (answer["success"] === true)
					swal.fire_success_href("Расписание успешно изменено!", "");
				else swal.fire_error("Произошла ошибка");
			} else swal.fire_error("Кажется вы не внесли никаких изменений");
		};
		updateSchedule();
	};

	const change_lesson = (e: any, lesson: any, day: any) => {
		if (!is_to_update_lessons) is_to_update_lessons = true;
		if (!updated_lessons[lesson._id]) {
			updated_lessons[lesson._id] = {};
			updated_lessons[lesson._id]["day_id"] = day.day_id;
			updated_lessons[lesson._id]["class_n"] = day.class_n;
			updated_lessons[lesson._id]["lesson_n"] = lesson.lesson_n;
			updated_lessons[lesson._id]["week_d_name"] = day.week_d_name;
		}
		updated_lessons[lesson._id][e.target.id] = e.target.value;
	};

	return (
		<div className="h-full bg-white dark:bg-black flex flex-col pt-16 sm:pt-0">
			<div className="mx-auto w-72 mt-20 sm:mt-5">
				<select
					name="class_n"
					placeholder="Выберите класс"
					defaultValue={String(selectedClass)}
					onChange={(e) => setSelectedClass(e.target.value)}
					className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
				>
					<option disabled>Выберите класс</option>
					<option value="1">1 Класс</option>
					<option value="2">2 Класс</option>
					<option value="3">3 Класс</option>
					<option value="4">4 Класс</option>
					<option value="5">5 Класс</option>
					<option value="6">6 Класс</option>
					<option value="7">7 Класс</option>
					<option value="8">8 Класс</option>
					<option value="9">9 Класс</option>
					<option value="10">10 Класс</option>
					<option value="11">11 Класс</option>
				</select>
			</div>
			<div className="w-96 mx-auto  rounded-xl py-3">
				<div className="bg-slate-200 rounded-xl py-3 px-2 text-center font-bold">
					<h1 className="">Сдвиг в неделях относительно текущей</h1>
					<div className="flex w-full rounded-lg items-center text-center">
						<i
							className="bi bi-caret-left-fill text-3xl mb-1"
							onClick={() => setWeek_off(week_off - 1)}
						/>
						<input
							type="number"
							className="w-full rounded-2xl py-1.5 text-center"
							value={week_off}
							onChange={(e) => setWeek_off(Number(e.target.value))}
						/>
						<i
							className="bi bi-caret-right-fill text-3xl mb-1"
							onClick={() => setWeek_off(week_off + 1)}
						/>
					</div>
				</div>
			</div>
			{schedule !== undefined && IsReadyToShow ? (
				<div>
					<div className="w-72 mx-auto  rounded-xl py-3 my-2">
						<button
							className="mx-auto mt-3 pt-3 pb-3 px-8 mb-1 rounded-xl border-2 text-red-500 border-red-500 hover:bg-red-500 hover:border-red-300 hover:mb-0 hover:border-4 hover:text-white"
							onClick={send_updates}
						>
							Тыкнуть для отправки поправок в расписании
						</button>
					</div>

					<div className="flex flex-wrap px-12">
						{schedule.map((day: any, idx1: any) => {
							let lessons = day["lessons"];
							let week_d: string = day["week_d_name"];
							return (
								<div
									className="bg-red-400 w-96 rounded-xl text-center mx-auto pt-2 pb-3 my-2"
									key={`DivDaySchedule ${idx1}`}
								>
									<h1 className="text-xl font-bold">
										{days_dict[week_d]} {day["date"]}
									</h1>
									{lessons.map((lesson: any, idx2: any) => {
										// return <p className="py-4">{lesson.name}</p>;
										return (
											<div
												className={`w-3/4 mx-auto py-2 px-2 ${
													lesson.type === ""
														? "bg-slate-100"
														: lesson_types[lesson.type]
												} rounded-2xl my-2`}
												key={`DivLessonSchedule ${idx2}`}
											>
												<p className="font-bold">{idx2 + 1}</p>
												<RequireRankToShow type="ge" rank="3">
													<input
														type="text"
														id="lesson_name"
														className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full mb-1"
														placeholder="Урок"
														defaultValue={lesson.name}
														onChange={(e) => change_lesson(e, lesson, day)}
													/>
												</RequireRankToShow>
												<RequireRankToShow type="e" rank="2">
													<p
														id="lesson_name"
														className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full mb-1 cursor-not-allowed"
													>
														{lesson.name}
													</p>
												</RequireRankToShow>

												<input
													type="text"
													id="lesson_notes"
													className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
													placeholder="Пометка к уроку"
													defaultValue={lesson.notes}
													onChange={(e) => change_lesson(e, lesson, day)}
												/>
												<select
													className="border-2 bg-slate-200 rounded-xl text-center py-1 mt-1 w-full"
													defaultValue={lesson.type}
													id="lesson_type"
													onChange={(e) => change_lesson(e, lesson, day)}
												>
													<option value="" className="text-gray-400">
														Тип урока(необязательно)
													</option>
													{Object.keys(lesson_types).map((type, i) => (
														<option
															key={`lesson_type_option_key ${i}`}
															value={type}
															className="text-black"
														>
															{type}
														</option>
													))}
												</select>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div>
					<h1>ЗАГРУЗКА</h1>
				</div>
			)}
		</div>
	);
};

export default Schedule;
