import { GetRank } from "../../../Functions/diff";

export function FullChechFormCreate(user: any) {
  let rank = GetRank();
  let msg = {
    status: "success",
    msg: "",
  };
  if (user.first_name === "") {
    msg.status = "fail";
    msg.msg = "Введите имя";
  } else if (user.sur_name === "") {
    msg.status = "fail";
    msg.msg = "Введите фамилию";
  } else if (user.email === "") {
    msg.status = "fail";
    msg.msg = "Введите адрес электронной почты";
  } else if (user.rank === "") {
    msg.status = "fail";
    msg.msg = "Введите ранг";
  } else if (user.rank >= rank) {
    msg.status = "fail";
    msg.msg = "Вы не можете ставить ранг такой же как у вас или выше";
  } else if ((user.rank < 3 && Number(user.rank) !== 1 ) && user.class_n === "") {
      msg.status = "fail";
      msg.msg = "Ранг меньше 3 и не равен 1, введите класс";
  } else if ((user.rank < 3 && Number(user.rank) !== 1 ) && user.class_n < 1) {
    msg.status = "fail";
    msg.msg = "Класс должен быть больше или равен 1";
  } else if ((user.rank < 3 && Number(user.rank) !== 1 ) && user.class_n > 11) {
    msg.status = "fail";
    msg.msg = "Класс должен быть меньше или равен 11";
  }

  return msg;
}

export function FullChechFormUpdateUser(data: any) {
  let rank = GetRank();
  let msg = {
    status: "success",
    msg: "",
  };
  if (data.first_name === "") {
    msg.status = "fail";
    msg.msg = "Введите имя";
  } else if (data.sur_name === "") {
    msg.status = "fail";
    msg.msg = "Введите фамилию";
  } else if (data.rank >= rank) {
    msg.status = "fail";
    msg.msg = "Вы не можете ставить ранг такой же как у вас или выше";
  } else if ((data.rank < 3 && Number(data.rank) !== 1 ) && data.class_n === "") {
      msg.status = "fail";
      msg.msg = "Ранг меньше 3 и не равен 1, введите класс";
  } else if ((data.rank < 3 && Number(data.rank) !== 1 ) && data.class_n < 1) {
    msg.status = "fail";
    msg.msg = "Класс должен быть больше или равен 1";
  } else if ((data.rank === 1) && data.class_n !== 0) {
    msg.status = "fail";
    msg.msg = "Для учителей класс должен быть равен 0";
  } else if ((data.rank < 3 && Number(data.rank) !== 1 ) && data.class_n > 11) {
    msg.status = "fail";
    msg.msg = "Класс должен быть меньше или равен 11";
  }

  return msg;
}
