import React from "react";

const UserCardInTeacherHomework = ({
  user,
  setUsersToHomeWork,
  usersToHomeWork,
}: any) => {
  const addToHomework = () => {
    if (user["user_id"]["rank"] !== 1) {
      if (
        usersToHomeWork.filter(
          (t: any) => t.id === user["user_id"]["_id"]
        )[0] === undefined
      ) {
        setUsersToHomeWork([
          {
            id: user["user_id"]["_id"],
            data: {
              first_name: user["user_id"]["first_name"],
              sur_name: user["user_id"]["sur_name"],
            },
          },
          ...usersToHomeWork,
        ]);
      }
    }
  };

  return (
    <div className="flex border-2 border-red-300 rounded-2xl py-4 w-full mt-3">
      <div className="flex w-2/3 items-center">
        <p className="mx-8">
          {user.user_id.first_name} {user.user_id.sur_name}
        </p>
      </div>

      <div className="flex w-full justify-end items-center">
        {/* <p className="mx-8">{data.class_n}</p> */}
        <i
          className="bi bi-plus-circle text-2xl pr-4"
          onClick={addToHomework}
        />
      </div>
    </div>
  );
};

export default UserCardInTeacherHomework;
