import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateUser from "./CreateUser";
import ChangeUser from "./ChangeUser";
import MainAdmin from "./MainAdmin";
import Schedule from "./Schedule";
import RequireRankToOpen from "../../../hoc/RequireRankToOpen";
import TeacherPage from "./Teacher/TeacherPage";

const AdminPage = () => {
  return (
    <Routes>
      <Route path="" element={<MainAdmin />} />
      <Route // * Создать пользователя, ранг 4+
        path="create-user"
        element={
          <RequireRankToOpen rank="4" type="ge">
            <CreateUser />
          </RequireRankToOpen>
        }
      />
      <Route // * Изменение пользователя, ранг 2+
        path="change-user/:id"
        element={
          <RequireRankToOpen rank="2" type="ge">
            <ChangeUser />
          </RequireRankToOpen>
        }
      />
      <Route // * Расписание, ранг 2+
        path="schedule"
        element={
          <RequireRankToOpen rank="2" type="ge">
            <Schedule />
          </RequireRankToOpen>
        }
      />
      <Route // * Ксбинет учителя, ранг 1
        path="teacher/*"
        element={
          <RequireRankToOpen rank="1" type="e">
            <TeacherPage />
          </RequireRankToOpen>
        }
      />
    </Routes>
  );
};

export default AdminPage;
