export const lesson_types: { [key: string]: string } = {
    // type: color
    'Экскурсия': 'bg-yellow-400',
    'Сбор класса': 'bg-green-600',
    'Зачет': 'bg-rose-600',
    'Семинар': 'bg-sky-700'
}

// {Object.keys(lesson_types).map((keyName, i) => (
//     <li className="travelcompany-input" key={i}>
//       <span className="input-label">
//         key: {keyName} Name: {lesson_types[keyName]}
//       </span>
//     </li>
//   ))}