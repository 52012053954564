import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { siteContext } from "./siteContext";

export function IsLoggedIn() {
	if (localStorage.getItem("isLogged") === "true") return true;
	else return false;
}

// export function UpdateUser({ setIsLogged }: any) {
//   // if (localStorage.getItem("token") != null) {
//   axios.get(`http://dnevnik.covcheg.org/account/is-logged-in`).then((data) => {
//     setIsLogged(data.data);
//     if (data.data) {
//       axios.get(`http://dnevnik.covcheg.org/account/get-user`).then((data) => {
//         localStorage.setItem("user", JSON.stringify(data.data.user));
//       });
//     } else {
//       localStorage.removeItem("token");
//       localStorage.removeItem("user");
//     }
//   });
//   // }
// }

export function GetUser() {
	if (localStorage.getItem("user") != null) {
		const user = JSON.parse(localStorage.getItem("user")!);
		return user;
	} else return false;
}

export function GetRank() {
	let rank = 0;
	if (localStorage.getItem("user") != null) {
		rank = JSON.parse(localStorage.getItem("user")!).rank;
	}
	return rank;
}

const UpdateUser = () => {
	const { siteState, setSiteState } = useContext(siteContext);
	const [test, setTest] = useState(false);

	useEffect(() => {
		if (test !== true) {
			axios
				.get(`http://dnevnik.covcheg.org/account/is-logged-in`)
				.then((data) => {
					setSiteState((siteState: any) => ({
						...siteState,
						site: {
							...siteState.site,
							isLogged: data.data,
						},
					}));
					// console.log(data)
					if (data.data) {
						axios
							.get(`http://dnevnik.covcheg.org/account/get-user`)
							.then((data) => {
								localStorage.setItem("user", JSON.stringify(data.data.user));
							});
					} else {
						localStorage.removeItem("token");
						localStorage.removeItem("user");
					}
				});
			setTest(true);
		}
	});

	return <div />;
};

export default UpdateUser;
