import React from "react";
import { Link } from "react-router-dom";
import RequireRankToShow from "../../../hoc/RequireRankToShow";
import UserList from "./UserList";

const MainAdmin = () => {
  return (
    <div className="h-full bg-white flex flex-col items-center">
      <h1 className="mt-10 sm:mt-3">Панель администратора</h1>
      <RequireRankToShow rank="4" type="ge">
        <Link
          to="/admin/create-user"
          className="mt-4 py-3 px-16 rounded-xl border-2 text-red-500 border-red-500 hover:bg-red-500 hover:text-white"
        >
          Создать пользователя
        </Link>
      </RequireRankToShow>
      <UserList />
    </div>
  );
};

export default MainAdmin;
