import React, { useContext, useEffect, useLayoutEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

import Nav from "./components/Nav";

import HomePage from "./components/Screens/Home/HomePage";
import AccountPage from "./components/Screens/Account/AccountPage";
import AdminPage from "./components/Screens/Admin/AdminPage";
import RequireRankToOpen from "./hoc/RequireRankToOpen";
import UpdateUser from "./components/Functions/diff";
import { siteContext } from "./components/Functions/siteContext";
import { MemoryRouter } from "react-router";

const App = () => {
	const { siteState, setSiteState } = useContext(siteContext);

	useEffect(() => {
		const theme = siteState.site.theme;
		if (theme === "dark") {
			document.documentElement.classList.add("dark");
		} else {
			document.documentElement.classList.remove("dark");
		}
	}, [siteState]);

	useLayoutEffect(() => {
		const theme = localStorage.getItem("theme");
		if (theme === null) localStorage.setItem("theme", siteState.site.theme);
		else
			setSiteState((siteState: any) => ({
				...siteState,
				site: {
					...siteState.site,
					theme: theme,
				},
			}));
	}, []);

	return (
		<MemoryRouter>
			<div className="h-screen">
				<UpdateUser />
				<Nav />
				<div className="h-full lg:ml-[300px]">
					<Routes>
						<Route path="" element={<Navigate to="/home" />} />
						<Route path="/home/*" element={<HomePage />} />
						<Route path="/account/*" element={<AccountPage />} />
						<Route
							path="/admin/*"
							element={
								<RequireRankToOpen rank="1" type="ge">
									<AdminPage />
								</RequireRankToOpen>
							}
						/>
					</Routes>
				</div>
			</div>
		</MemoryRouter>
	);
};

export default App;
