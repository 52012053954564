import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { siteContext } from "./Functions/siteContext";
import RequireRankToShow from "../hoc/RequireRankToShow";
import useOutsideAlerter from "./Functions/useOutside";
import cn from "classnames";
import * as swal from "./Functions/SwallFire";

const Nav = () => {
	const { siteState, setSiteState } = useContext(siteContext);
	const { ref, isOpen, setIsOpen } = useOutsideAlerter(false);

	function dropDown_admin() {
		document.querySelector("#submenu_admin")?.classList.toggle("hidden");
		document.querySelector("#arrow")?.classList.toggle("rotate-90");
	}
	dropDown_admin();

	function Openbar() {
		setIsOpen(!isOpen);
		// document.querySelector(".sidebar")?.classList.toggle("left-[-300px]");
	}

	function changeTheme() {
		swal.fire_error(
			"Разработчик очень ленивый... Данная функция вскоре будет реализована, но пока к сожалению еще нет"
		);
		// let theme = localStorage.getItem("theme");
		// if (theme === "light") theme = "dark";
		// else theme = "light";
		// localStorage.setItem("theme", theme);
		// setSiteState((siteState: any) => ({
		//   ...siteState,
		//   site: {
		//     ...siteState.site,
		//     theme: theme,
		//   },
		// }));
	}

	return (
		<div>
			<div
				className={cn(
					"fixed flex bottom-0 right-0 sm:h-14 sm:w-14 h-10 w-10 mr-1 mb-1 items-center justify-center sm:rounded-xl rounded-lg cursor-pointer",
					{
						"bg-slate-900 text-white": siteState.site.theme === "light",
						"bg-slate-100 text-black": siteState.site.theme === "dark",
					}
				)}
				onClick={changeTheme}
			>
				{siteState.site.theme === "light" ? (
					<i className="bi bi-moon sm:text-5xl text-3xl" />
				) : (
					<i className="bi bi-brightness-high sm:text-5xl text-3xl" />
				)}
			</div>
			<span
				className="absolute text-white text-4xl top-5 left-4 cursor-pointer"
				onClick={Openbar}
			>
				<i className="bi bi-filter-left px-2 bg-gray-900 rounded-md"></i>
			</span>
			<div
				ref={ref}
				className={cn(
					"sidebar fixed top-0 bottom-0 lg:left-0 lg:-translate-x-0 duration-700 p-2 w-[300px] overflow-y-auto text-center bg-gray-900 shadow h-screen transition transform",
					{
						"-translate-x-[300px]": !isOpen,
					}
				)}
			>
				<div className="text-gray-100 text-xl">
					<div className="p-2.5 mt-1 flex items-center rounded-md ">
						<i className="bi bi-app-indicator px-2 py-1 bg-blue-600 rounded-md"></i>
						<h1 className="text-[15px]  ml-3 text-xl text-gray-200 font-bold">
							Навигация
						</h1>
						<i
							className="bi bi-x ml-20 cursor-pointer lg:hidden"
							onClick={Openbar}
						></i>
					</div>
					<hr className="my-2 text-gray-600" />

					<div>
						<Link
							to="/"
							className="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer  hover:bg-blue-600"
						>
							<i className="bi bi-house-door-fill" />
							<span className="text-[15px] ml-4 text-gray-200">
								Домашняя страница
							</span>
						</Link>
						{siteState.site.isLogged ? (
							<div>
								<Link
									to="/home/homework"
									className="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer  hover:bg-blue-600"
								>
									<i className="bi bi-list-task" />
									<span className="text-[15px] ml-4 text-gray-200">
										Домашние работы
									</span>
								</Link>
								<Link
									to="/account/"
									className="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer  hover:bg-blue-600"
								>
									<i className="bi bi-person-circle" />
									<span className="text-[15px] ml-4 text-gray-200">
										Личный кабинет
									</span>
								</Link>
							</div>
						) : (
							<Link
								to="/account/login/"
								className="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer  hover:bg-blue-600"
							>
								<i className="bi bi-person-circle"></i>
								<span className="text-[15px] ml-4 text-gray-200">Войти</span>
							</Link>
						)}
						<RequireRankToShow rank="1" type="ge">
							<div>
								<hr className="my-4 text-gray-600" />
								<div className="p-2.5 mt-2 flex items-center rounded-md px-4 duration-300 cursor-pointer  hover:bg-blue-600">
									<i className="bi bi-broadcast"></i>
									<div
										className="flex justify-between w-full items-center"
										onClick={dropDown_admin}
									>
										<span className="text-[15px] ml-4 text-gray-200">
											Администратирование
										</span>
										<span className="text-sm rotate-90" id="arrow">
											<i className="bi bi-chevron-down"></i>
										</span>
									</div>
								</div>
								<div
									className=" leading-7 text-left text-sm font-thin mt-2 w-4/5 mx-auto"
									id="submenu_admin"
								>
									<RequireRankToShow rank="2" type="ge">
										<Link to="/admin">
											<h1 className="cursor-pointer p-2 hover:bg-gray-700 rounded-md mt-1">
												Главная панель
											</h1>
										</Link>
										<Link to="/admin/schedule">
											<h1 className="cursor-pointer p-2 hover:bg-gray-700 rounded-md mt-1">
												Расписание
											</h1>
										</Link>
									</RequireRankToShow>
									<RequireRankToShow rank="1" type="e">
										<Link to="admin/teacher">
											<h1 className="cursor-pointer p-2 hover:bg-gray-700 rounded-md mt-1">
												Кабинет учителя
											</h1>
										</Link>
									</RequireRankToShow>
								</div>
							</div>
						</RequireRankToShow>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Nav;
