import React from "react";
import { Link } from "react-router-dom";

const UserCardInAdminPanel = ({ data }: any) => {

  return (
    <div className="flex border-2 border-red-300 rounded-2xl py-4 w-full mt-3">
      <p className="mx-8">
        {data.first_name} {data.sur_name}
      </p>
      <div className="flex w-full justify-end items-center">
        <p className="w-24 text-left">Ранг: {data.rank}</p>
        {/* <p className="mx-8">{data.class_n}</p> */}
        <Link to={`change-user/${data._id}`} className="mx-8">
          Изменить
        </Link>
      </div>
    </div>
  );
};

export default UserCardInAdminPanel;
