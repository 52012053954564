import {
  useEffect,
  useRef,
  useState,
} from "react";

export default function useOutsideAlerter(initialIsOpen: boolean) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const ref = useRef<any>(null);

  const handlOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handlOutside, true);
    return () => {
      document.removeEventListener("click", handlOutside, true);
    };
  });

  return { ref, isOpen, setIsOpen };
};