import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import "./index.css";
import axios from "axios";
import { siteContext, initialState } from "./components/Functions/siteContext";
import { useState } from "react";


axios.interceptors.request.use(function (config) {
  const token: string = localStorage.getItem("token")!;
  config.headers!.Authorization = token;

  return config;
});

const container = document.getElementById("root")!;
const root = ReactDOMClient.createRoot(container);

function Main() {
  const [siteState, setSiteState] = useState(initialState);
  return (
		<siteContext.Provider value={{ siteState, setSiteState }}>
				<App />
		</siteContext.Provider>
	);
}

// Initial render: рендеринг элемента в root.
root.render(<Main />);