export function checkAllFormLogin(data: any) {
  let msg = {
    status: "success",
    msg: "",
  };
  if (data.email === '') {
    msg.status = "fail";
    msg.msg = "Введите адрес электронной почты";
  } else if (data.password === '') {
    msg.status = "fail";
    msg.msg = "Введите пароль";
  }

  return msg;
}

export function checkPassChange(data: any) {
    let msg = {
      status: 'success',
      msg: '',
    };
    if (data.password_before === '') {
      msg.status = 'fail';
      msg.msg = 'Введите ваш старый пароль';
    } else if (data.password_new === '') {
      msg.status = 'fail';
      msg.msg = 'Введите новый пароль';
    } else if (data.password_new !== data.password_new_repeat) {
      msg.status = 'fail';
      msg.msg = 'Новые пароли не совпадают';
    }

    return msg;
  }