import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GetUser } from "../../Functions/diff";
import * as swal from "../../Functions/SwallFire";
import { lesson_types } from "../../Functions/consts/constants";
// import cn from "classnames";
import UseOutside from "../../Functions/useOutside";
import { siteContext } from "../../Functions/siteContext";

const MainHome = () => {
	const main_user = GetUser();
	const days_dict: { [key: string]: string } = {
		Mon: "Понедельник",
		Tue: "Вторник",
		Wed: "Среда",
		Thu: "Четверг",
		Fri: "Пятница",
	};

	const [schedule, setSchedule] = useState<any>([]);
	const [IsReadyToShow, setReadyToShow] = useState(false);
	const [week_off, setWeek_off] = useState(0);
	const [homeworkLessons, setHomeworkLessons] = useState<string[]>([]);
	const [allHomework, setAllHomework] = useState<any>([]);
	const [dispHomework, setDispHomework] = useState<any>(false);

	const [selectedClass, setSelectedClass] = useState("1");

	const { ref, isOpen, setIsOpen } = UseOutside(false);
	const { siteState, setSiteState } = useContext(siteContext);
	const [lessonOpen, setLessonOpen] = useState<any>(false);

	useEffect(() => {
		const getSchedule = async () => {
			setReadyToShow(false);
			const url = `http://dnevnik.covcheg.org/schedule/get-schedule?class_n=${selectedClass}&week_off=${week_off}`;
			let answer = (await axios.post(url)).data;
			if (answer["success"] === true) setSchedule(answer.msg);
			else {
				if (answer.side === "right") setWeek_off(week_off - 1);
				if (answer.side === "left") setWeek_off(week_off + 1);
				swal.fire_error(answer.msg);
			}
			setReadyToShow(true);
		};

		if (main_user.rank === 0 && siteState.site.isLogged) {
			const url_get_hw = `http://dnevnik.covcheg.org/schedule/get-user-week-hw?user_id=${main_user.id}&week_off=${week_off}`;

			axios.post(url_get_hw).then(({ data }) => {
				if (data.success) {
					data.msg.forEach((hw: any) => {
						setHomeworkLessons((prev: any) => [...prev, hw.lesson]);
					});
					setAllHomework(data.msg);
				}
			});
		}

		getSchedule();
	}, [week_off, selectedClass]);

	useEffect(() => {
		if (main_user.rank === 0 && siteState.site.isLogged)
			setSelectedClass(main_user.class_n);
	}, [IsReadyToShow]);

	useEffect(() => {
		setDispHomework(
			allHomework.filter((t: any) => t.lesson === lessonOpen._id)[0]
		);
	}, [lessonOpen]);

	const is_in_homeworkLessons = (lesson_id: any) => {
		return homeworkLessons.indexOf(lesson_id) !== -1;
	};

	return (
		<div className="h-full bg-white dark:bg-black flex flex-col pt-16 sm:pt-0">
			{/* // * Вывод домашней работы */}
			<div
				className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-sky-300 rounded-3xl duration-700 scale-[1.5] ${
					!isOpen ? "scale-0" : ""
				}`}
				ref={ref}
			>
				<div className="flex text-center items-center justify-center">
					{dispHomework && (
						<div className="py-5 px-8">
							<p className="font-bold lg:text-3xl sm:text-2xl text-xl mb-3">
								Задание: {dispHomework.title}
							</p>
							<p className="lg:text-lg sm:text-base text-sm mb-2">
								Описание: {dispHomework.description}
							</p>
							{dispHomework.links !== "" && (
								<div>
									<p>Ссылки:</p>
									{dispHomework.links.split(" ").map((link: any, idx: any) => {
										return (
											<a
												href={link}
												className="lg:text-base sm:text-sm text-xs text-blue-900"
											>
												<p>{link}</p>
											</a>
										);
									})}
								</div>
							)}
						</div>
					)}
				</div>
			</div>

			{(!siteState.site.isLogged || main_user.rank !== 0) && (
				<div className="mx-auto w-72 mt-20 sm:mt-5">
					<select
						name="class_n"
						placeholder="Выберите класс"
						defaultValue={String(selectedClass)}
						onChange={(e) => setSelectedClass(e.target.value)}
						className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
					>
						<option disabled>Выберите класс</option>
						<option value="1">1 Класс</option>
						<option value="2">2 Класс</option>
						<option value="3">3 Класс</option>
						<option value="4">4 Класс</option>
						<option value="5">5 Класс</option>
						<option value="6">6 Класс</option>
						<option value="7">7 Класс</option>
						<option value="8">8 Класс</option>
						<option value="9">9 Класс</option>
						<option value="10">10 Класс</option>
						<option value="11">11 Класс</option>
					</select>
				</div>
			)}

			<div className="sm:w-96 mx-auto  rounded-xl py-3">
				<div className="bg-slate-200 rounded-xl py-3 px-2 text-center font-bold">
					<h1 className="">Сдвиг в неделях относительно текущей</h1>
					<div className="flex w-full rounded-lg items-center text-center">
						<i
							className="bi bi-caret-left-fill text-3xl mb-1"
							onClick={() => setWeek_off(week_off - 1)}
						/>
						<input
							type="number"
							className="w-full rounded-2xl py-1.5 text-center"
							value={week_off}
							onChange={(e) => setWeek_off(Number(e.target.value))}
						/>
						<i
							className="bi bi-caret-right-fill text-3xl mb-1"
							onClick={() => setWeek_off(week_off + 1)}
						/>
					</div>
				</div>
			</div>

			{schedule !== undefined && IsReadyToShow ? (
				<div>
					<div className="flex flex-wrap sm:px-12">
						{schedule.map((day: any, idx1: any) => {
							let lessons = day["lessons"];
							let week_d: string = day["week_d_name"];
							return (
								<div
									className="bg-red-400 w-96 rounded-3xl text-center mx-auto pt-2 pb-3 my-2"
									key={`DivDaySchedule ${idx1}`}
								>
									<h1 className="text-xl font-bold">
										{days_dict[week_d]} {day["date"]}
									</h1>
									{lessons.map((lesson: any, idx2: any) => {
										return (
											<div
												className={`sm:w-3/4 w-5/6 mx-auto py-2 px-2 ${
													lesson.type === ""
														? "bg-slate-100"
														: lesson_types[lesson.type]
												} rounded-2xl my-2`}
												key={`DivLessonSchedule ${idx2}`}
												onClick={() => {
													if (is_in_homeworkLessons(lesson._id)) {
														setLessonOpen(lesson);
														setIsOpen(true);
													}
												}}
											>
												<span className="flex">
													{is_in_homeworkLessons(lesson._id) && (
														<span className="inline-flex rounded-full h-3 w-3 bg-rose-900" />
													)}

													<p className="font-bold mx-auto">{idx2 + 1}</p>
												</span>

												<p
													id="lesson_name"
													className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full mb-1"
												>
													{lesson.name}
												</p>

												<p
													id="lesson_notes"
													className="border-2 bg-slate-200 rounded-xl text-center py-1 w-full"
												>
													{lesson.notes === "" ? "Нет пометок" : lesson.notes}
												</p>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div>
					<h1>ЗАГРУЗКА</h1>
				</div>
			)}
		</div>
	);
};

export default MainHome;
